import { API } from "aws-amplify";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import React, { Component } from "react";
import { Col, Grid, Row } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import Loader from "../Loader/Loader";
import FormRow from "./FormRow";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity: props.entity,
      isLoading: true,
      responseData: {},
      resourceData: {},
      defaults: {},
    };
    this.validator = new SimpleReactValidator({ className: "text-danger" });
  }

  getDefaultValues = async (entity) => {
    if (entity === undefined) {
      entity = this.state.entity;
    }
    let entityDefaults = {};
    Object.keys(entity)
      .filter((value) => value !== "__validator__")
      .map((key) => {
        return (entityDefaults[key] = entity[key].value);
      });
    // Response maps against {key: label} dict which may not exist
    // at this moment (still being fetched over the Internet)
    let responseData = this.props.responseData || this.state.responseData;
    if (Object.keys(responseData).length !== 0) {
      await this.mapData(
        "responseNormalizer",
        entityDefaults,
        responseData,
        entity
      );
    }
    Object.assign(entityDefaults, this.state.resourceData);
    return entityDefaults;
  };

  prepareRequestData = async () => {
    let requestData = {};
    await this.mapData(
      "requestNormalizer",
      requestData,
      await this.getDefaultValues(),
      this.state.entity
    );
    return requestData;
  };

  handleSubmit = async (event) => {
    event.persist();
    event.target.disabled = true;
    if (this.validator.allValid()) {
      event.preventDefault();
      let requestData = { body: await this.prepareRequestData() };
      let actionUrl =
        (this.props.actionUrl || this.props.url) +
        (this.props.id ? "/" + this.props.id : "");
      let resultPromise = this.props.id
        ? API.put("admin", actionUrl, requestData)
        : API.post("admin", actionUrl, requestData);
      resultPromise
        .then((data) => {
          if (this.props.onSuccess) {
            event.preventDefault();
            this.props.onSuccess();
          }
        })
        .catch((error) => {
          this.props.handleClick(
            error.response.data.error || error.response.data.message,
            "error",
            "tr"
          );
        })
        .finally(() => {
          event.target.disabled = false;
        });
    } else {
      this.validator.showMessages();
      event.preventDefault();
      event.target.disabled = false;
      this.forceUpdate();
    }
  };

  handleInput = (event) => {
    const {
      target: { name, value },
    } = event;

    this.reloadDefaults(name, value);
  };
  handleSelect = (value, event) => {
    this.reloadDefaults(event.name, value);
  };
  handleCollection = (_State, name) => {
    this.reloadDefaults(name, _State);
  };

  mapData = async (normalizer, result, data, object) => {
    for (const key of Object.keys(object).filter(
      (value) => value !== "__validator__"
    )) {
      let currentElement = data[key];
      if (object[key].prototype !== undefined) {
        let childs = currentElement;
        let proto = object[key].prototype;

        currentElement = await Promise.all(
          Object.keys(childs).map(async (key) => {
            let child = {};
            await this.mapData(normalizer, child, childs[key], proto);
            return child;
          })
        );
      }
      result[key] =
        object[key][normalizer] === undefined
          ? currentElement
          : await Promise.resolve(object[key][normalizer](currentElement)).then(
              (value) => {
                return value;
              }
            );
    }
  };

  reloadDefaults(name, value) {
    this.setState((prevState) => {
      let resourceData = Object.assign({}, prevState.resourceData);
      let defaults = Object.assign({}, prevState.defaults);
      resourceData[name] = value;
      defaults[name] = value;
      return { resourceData, defaults };
    });
  }

  componentDidMount() {
    if (!this.props.skipPrepopulation && this.props.id) {
      API.get(
        "admin",
        this.props.getEntityUrl || this.props.url + "/" + this.props.id
      ).then((data) => {
        this.setState({ responseData: data });

        this.getDefaultValues().then((value) => {
          this.setState({ defaults: value, isLoading: false });
        });
      });
    } else {
      this.getDefaultValues().then((value) => {
        this.setState({ defaults: value, isLoading: false });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const entity = nextProps.entity;
    this.getDefaultValues(entity).then((value) => {
      this.setState({ defaults: value, entity: entity });
    });
  }

  render() {
    let defaults = this.state.defaults;
    let title = this.props.title;
    if (typeof this.props.title === "function") {
      title = this.props.title(defaults);
    }
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={this.props.md || 6}>
              {this.props.headerChild}
              <Card
                title={title}
                content={
                  <Loader isLoading={this.state.isLoading}>
                    <form onSubmit={this.handleSubmit}>
                      {Object.keys(this.state.entity)
                        .filter((value) => value !== "__validator__")
                        .map((key, index) => {
                          if (!this.state.entity[key].hidden) {
                            return (
                              <FormRow
                                key={index}
                                name={key}
                                altname={this.state.entity[key].altname}
                                type={this.state.entity[key].type || "input"}
                                inputType={this.state.entity[key].inputType}
                                value={defaults[key]}
                                validationRules={
                                  this.state.entity[key].validationRules || ""
                                }
                                onChangeEvent={
                                  this[this.state.entity[key].onChangeEvent] ||
                                  this.handleInput
                                }
                                selectOptions={
                                  this.state.entity[key].selectOptions || null
                                }
                                prototype={
                                  this.state.entity[key].prototype || null
                                }
                                validator={this.validator}
                                md={this.state.entity[key].md || null}
                                selectProps={this.state.entity[key].selectProps}
                                defaultValue={
                                  this.state.entity[key].defaultValue || null
                                }
                              />
                            );
                          }
                          return null;
                        })}
                      <Button
                        bsStyle="primary"
                        type="submit"
                        onClick={async (e) => {
                          this.handleSubmit(e);
                        }}
                      >
                        Save
                      </Button>
                      <div className="clearfix" />
                    </form>
                  </Loader>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Form;
