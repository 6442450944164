import { API } from "aws-amplify";
import React from "react";
import { NavLink } from "react-router-dom";
import { components } from "react-select";
import { buildSelector, constToSelectOptions } from "../utils/Utils";

const Option = ({ children, ...props }) => {
  let option = props.data;
  return (
    <components.Option {...props}>
      <div className="clearfix">
        <p style={{ margin: "0" }}>
          <NavLink
            to={{
              pathname: "/admin/workout-blocks/edit/" + option.id,
            }}
            target="_blank"
            style={{ color: "black" }}
          >
            <strong>
              [{option.id}] {option.internal_name}
            </strong>{" "}
            <span className="glyphicon glyphicon-new-window" />
          </NavLink>
        </p>

        <div className="mb-9 pull-left" style={{ marginLeft: "10px" }}>
          {/*<p style={{"margin": "0"}}><strong>{`[${option.id}] ${option.internal_name}`}</strong></p>*/}
          <p style={{ margin: "0" }}>
            <strong>{`Block Type: ${option.block_type}`}</strong>
          </p>
        </div>
      </div>
    </components.Option>
  );
};

export const WorkoutBlockIds = (constants, loadOptions) => {
  let objCache = {};
  return {
    id: {
      type: "select",
      validationRules: "required",
      onChangeEvent: "handleSelect",
      value: "",
      requestNormalizer: (option) => {
        return option.id;
      },
      responseNormalizer: (option) => {
        let block_id = option;
        if (objCache[block_id] !== undefined) {
          return objCache[block_id];
        }
        return API.get("admin", `/admin/workout-blocks/${block_id}`)
          .then((data) => {
            objCache[block_id] = data;
            return data;
          })
          .catch((error) => {
            console.error(error);
            return {};
          });
      },
      selectProps: {
        components: { Option },
        getOptionLabel: (option) => {
          return `[${option["id"]}] ${option["internal_name"]}; ${option["block_type"]}`;
        },
        getOptionValue: (option) => {
          return option["id"];
        },
        pageSize: 10,
        minMenuHeight: 300,
        maxMenuHeight: 1000,
        loadOptions: loadOptions,
        // defaultOptions: true,
      },
      md: 10,
    },
  };
};

export const Workout = (constants, loadOptions) => {
  return {
    name: {
      type: "input",
      validationRules: "required",
      value: "",
      md: 4,
    },
    type: buildSelector(
      constToSelectOptions(constants, "workout_type"),
      "select",
      undefined,
      undefined,
      4
    ),
    tag: {
      type: "input",
      validationRules: "required",
      value: "",
      md: 4,
    },
    image_url: {
      type: "input",
      validationRules: ["required", { regex: "^https://mobile.appscdn.io/.*" }],
      value: "",
    },
    activity_type: buildSelector(
      constToSelectOptions(constants, "workout_activity_type"),
      "select",
      undefined,
      undefined,
      4
    ),
    description: {
      type: 'input',
      value: '',
      selectProps: {
        rows: 7,
        componentClass: "textarea",
        as: "textarea",
      },
    },
    blocks_v2: {
      type: "table_collection",
      value: [],
      prototype: WorkoutBlockIds(constants, loadOptions),
      onChangeEvent: "handleCollection",
      validationRules: "required",
      requestNormalizer: function (data) {
        return data.map((elem) => elem.id);
      },
    },
  };
};
